import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Moment from "react-moment"
import { Link } from "gatsby"
import _ from "lodash"

import { useLocation, navigate } from "@reach/router"
import queryString from "query-string"

// Default Filters by Type
const filtersTypeDefault = {
  Todos: {
    type: "Todos",
    label: "Todos",
  },
  Cuna: {
    type: "Cuna",
    label: "Cuña",
  },
  Programa: {
    type: "Programa",
    label: "Programa",
  },
  Entrevista: {
    type: "Entrevista",
    label: "Entrevista",
  },
  Otro: {
    type: "Otro",
    label: "Otro",
  },
}

const AudioGrid = ({ allAudios }) => {
  const location = useLocation()
  // Get variables (t,c) from Url params
  let { t, c } = queryString.parse(location.search)
  t = t in filtersTypeDefault ? t : "Todos"

  const [categories, setCategories] = useState([])
  const [filterType, updateFilterType] = useState(t || "Todos")
  const [filterCategory, updateFilterCategory] = useState(c || "Todos")
  const [filteredAudios, setFilteredAudios] = useState(allAudios)

  useEffect(() => {
    if (filterType === "Todos") {
      updateCategories(allAudios)
      if (filterCategory === "Todos") {
        setFilteredAudios(allAudios)
      } else {
        let filteredArr = _.filter(allAudios, function (el) {
          return _.filter(el.blog_subcategorias, { nombre: filterCategory })
            .length > 0
            ? el
            : null
        })
        setFilteredAudios(filteredArr)
      }
    } else {
      let filteredArr = _.filter(allAudios, { tipo: filterType })
      updateCategories(filteredArr)
      if (filterCategory === "Todos") {
        setFilteredAudios(filteredArr)
      } else {
        let filteredArr2 = _.filter(filteredArr, function (el) {
          return _.filter(el.blog_subcategorias, { nombre: filterCategory })
            .length > 0
            ? el
            : null
        })
        setFilteredAudios(filteredArr2)
      }
    }
  }, [filterType, filterCategory, allAudios])

  useEffect(() => {
    navigate(`?t=${filterType}&c=${filterCategory}`)
  }, [filterType, filterCategory])

  const updateCategories = arr => {
    let newCategories = [{ id: "01", slug: "todos", nombre: "Todos" }]
    arr.forEach(el => {
      el.blog_subcategorias.forEach(ca => {
        newCategories = [...newCategories, ca]
      })
    })
    setCategories(_.uniqBy(newCategories, "id"))
  }

  return (
    <div className="asm-media-grid">
      <div className="row justify-content-center mb-5">
        {Object.keys(filtersTypeDefault).map((f, i) => (
          <div className="form-check form-check-inline" key={i}>
            <input
              className="form-check-input"
              type="radio"
              name="filterTypeAudioRadios"
              id={`typeAudio${filtersTypeDefault[f].type}`}
              defaultValue={filtersTypeDefault[f].type || ""}
              onChange={() => updateFilterType(filtersTypeDefault[f].type)}
              {...(filterType === filtersTypeDefault[f].type
                ? { checked: true }
                : { checked: false })}
            />
            <label
              className="form-check-label"
              htmlFor={`typeAudio${filtersTypeDefault[f].type}`}
            >
              {filtersTypeDefault[f].label}
            </label>
          </div>
        ))}
      </div>
      <div className="row mb-5">
        {categories.map((c, i) => (
          <div className="form-check form-check-inline" key={i}>
            <input
              className="form-check-input"
              type="radio"
              name="filterCategoryAudioRadios"
              id={`categoryAudio${c.nombre}`}
              defaultValue={c.nombre || ""}
              onChange={() => updateFilterCategory(c.nombre)}
              {...(filterCategory === c.nombre
                ? { checked: true }
                : { checked: false })}
            />
            <label className="form-check-label" htmlFor={`categoryAudio${c.nombre}`}>
              {c.nombre}
            </label>
          </div>
        ))}
      </div>
      <div className="row row-cols-1 row-cols-md-3">
        {filteredAudios.length > 0 ? (
          filteredAudios.map((audio, index) => {
            return (
              <div className="col mb-5" key={index}>
                <div className="card h-100 rounded-0 border-0">
                  <audio
                    controls
                    className="w-100"
                    src={audio.archivo.publicURL}
                  >
                    <track default kind="captions" srcLang="en" />
                    Su navegador no soporta elementos de
                    <code>audio</code>.
                  </audio>
                  <div className="card-body">
                    {audio.tipo && (
                      <p className="card-subtitle">
                        <i className="fa fa-headphones mr-1"></i>
                        {audio.tipo}
                      </p>
                    )}
                    <h5 className="card-title">{audio.titulo}</h5>
                    <p className="card-text mb-0">
                      Publicado el&nbsp;
                      <Moment format="LL">{audio.publicado_en}</Moment>
                    </p>
                    <Link
                      to={`/audios/${audio.slug}`}
                      className="stretched-link"
                    ></Link>
                  </div>
                  <div className="card-footer">
                    <div className="card-tags">
                      {audio.blog_subcategorias.map((c, i) => (
                        <span key={i} className="badge badge-info mr-1">
                          {c.nombre}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="alert alert-info" role="alert">
            {`Ops! No encontramos ningún audio con la categoría "${filterCategory}"`}
          </div>
        )}
      </div>
    </div>
  )
}

AudioGrid.propTypes = {
  allAudios: PropTypes.arrayOf(
    PropTypes.shape({
      strapiId: PropTypes.string,
      slug: PropTypes.string.isRequired,
      titulo: PropTypes.string.isRequired,
      publicado_en: PropTypes.string.isRequired,
      archivo: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      tipo: PropTypes.string,
      blog_subcategorias: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          nombre: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

AudioGrid.defaultProps = {
  allAudios: [
    {
      strapiId: `5ff3ac07ce1b5e19cb07092b`,
      slug: `cambio-de-regimen`,
      titulo: `Cambio de régimen`,
      publicado_en: `2020-12-17T17:00:00.000Z`,
      archivo: {
        publicURL: `/static/0c481e87f2774b1bd41a0a70d9b70d11/4d1eaa21992d880810d7f98e7ceb959d.mp3`,
      },
      tipo: `Entrevista`,
      blog_subcategorias: [
        {
          slug: `vih`,
          nombre: `VIH`,
        },
      ],
    },
  ],
}

export default AudioGrid
