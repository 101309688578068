import { useStaticQuery, graphql } from "gatsby"

export const useAsmetAudios = () => {
  const data = useStaticQuery(
    graphql`
      query AudiosQuery {
        allStrapiMediaAudio(sort: { order: DESC, fields: publicado_en }) {
          edges {
            node {
              strapiId
              slug
              titulo
              meta {
                og_titulo
                og_imagen {
                  publicURL
                }
                og_descripcion
              }
              publicado_en
              descripcion
              archivo {
                publicURL
              }
              tipo
              blog_subcategorias {
                id
                slug
                nombre
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiMediaAudio.edges
}
